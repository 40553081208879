<template>
  <div>
    <div style="width: 100%; display: flex">
      <div class="storeList" style="width: 11%">
        <div class="title">
          <div class="span"></div>
          门店
        </div>
        <div
          class="store"
          v-for="(item, index) in storeList"
          v-bind:key="index"
          :class="hierarchy_id === item.hierarchy_id ? 'selectStore' : null"
          @click="handleStore(item)"
        >
          <div class="text">{{ item.hierarchy_name }}</div>
        </div>
      </div>
      <div style="width: 88%">
        <div class="tableTop">
          <div class="title" style="float: left">
            <div class="span"></div>
            积分使用规则
          </div>
          <div style="float: right; margin-right: 15px">
            <el-button
              type="primary"
              @click="handleAdd()"
              :disabled="isShareAble"
              >新增积分使用规则</el-button
            >
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" stripe height="720">
            <el-table-column
              align="center"
              prop="integral_proportion"
              label="抵扣1元需要积分值"
            ></el-table-column>
            <!-- <el-table-column
              align="center"
              prop="sales_return_integral_text"
              label="退货, 积分是否退还"
            ></el-table-column> -->
            <el-table-column
              align="center"
              prop="status_text"
              label="是否启用"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="add_user_realname"
              label="添加人"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="create_time"
              label="添加时间"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="operation"
              label="操作"
              width="180"
            >
              <template slot-scope="scope">
                <div class="options">
                  <el-select
                    v-model="scope.row.select"
                    placeholder="请选择"
                    @change="handleDetails($event, scope.row)"
                  >
                    <el-option
                      label="编辑"
                      :value="1"
                      :disabled="isShareAble"
                    ></el-option>
                    <el-option
                      label="删除"
                      :value="2"
                      :disabled="isShareAble"
                    ></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagina">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-sizes="[15, 30, 40, 50]"
            :page-size="15"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="visiable"
      :title="type === 'add' ? '新增积分使用规则' : '编辑积分使用规则'"
      :destroy-on-close="true"
      :before-close="cancelDia"
    >
      <el-form :model="integralForm" :rules="rules" ref="integralForm">
        <div class="form">
          <el-form-item
            label="抵扣1元需要的积分值"
            :label-width="formLabelWidth"
            prop="integral_proportion"
          >
            <el-input
              v-model="integralForm.integral_proportion"
              autocomplete="off"
              style="width: 60%"
              placeholder="请输入积分值"
            ></el-input>
            （最多可输入四位小数）
          </el-form-item>
          <!-- <el-form-item
            label="退货, 积分是否退还"
            :label-width="formLabelWidth"
          >
            <el-radio-group v-model="integralForm.sales_return_integral">
              <el-radio :label="20">是</el-radio>
              <el-radio :label="10">否</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="是否启用" :label-width="formLabelWidth">
            <el-radio-group v-model="integralForm.status">
              <el-radio :label="20">启用</el-radio>
              <el-radio :label="10">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div style="text-align: right">
          <el-button
            type="primary"
            @click="submit('integralForm')"
            :loading="loading"
            >保存</el-button
          >
          <el-button @click="cancelDia">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import storage from "good-storage";

import { getMerchantListTwo } from "@/api/goods/goodsStore/list";
import { getFind } from "@/api/system/integralRule";
import {
  integUseList,
  integUseDel,
  integUseCreate,
  integUseUpdate,
} from "@/api/member/integralSetting/index";
export default {
  data() {
    let validateNum = (rule, value, callback) => {
      if (!/^\d+(.\d{1,4})?$/.test(value)) {
        callback(new Error("请输入正整数，最多四位小数"));
      } else {
        callback();
      }
    };
    return {
      type: "add",
      loading: false,
      visiable: false,
      tableData: [],
      storeList: [],
      hierarchy_id: 0,
      userInfo: {},
      integralForm: {
        status: 20,
      },
      formLabelWidth: "160px",
      client_use_integral_rule_id: 0,
      share_type: 0,
      page: 1,
      limit: 15,
      total: 0,
      rules: {
        integral_proportion: [
          { required: true, message: "请输入积分值", trigger: "blur" },
          { validator: validateNum, trigger: "blur" },
        ],
      },
      isShareAble: false,
      type_id: null,
    };
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getRule();
    this.getMerchantListReq();
  },
  methods: {
    /*
     *  选择门店
     */
    handleStore(item) {
      this.type_id = item.type_id;
      this.hierarchy_id = item.hierarchy_id;
      this.getList();
    },
    /*
     *  积分规则
     */
    getRule() {
      getFind().then((res) => {
        if (res.code === 1) {
          if (res.data !== null) {
            this.share_type = res.data.share_type;
          }
        }
        this.getList();
      });
    },
    /*
     *  积分使用规则数据
     */
    getList() {
      const data = {
        hierarchy_id: this.hierarchy_id,
        page: this.page,
        limit: this.limit,
      };
      if (this.share_type === 1) {
        if (this.userInfo.hierarchy_type_id === 30 && this.type_id === 30) {
          this.isShareAble = false;
        } else {
          this.isShareAble = true;
        }
      } else if (this.share_type === 2) {
        if (this.userInfo.hierarchy_type_id === 30 && this.type_id === 30) {
          this.isShareAble = false;
        } else if (
          this.userInfo.hierarchy_type_id === 40 &&
          this.type_id === 30
        ) {
          this.isShareAble = true;
        } else if (
          this.userInfo.hierarchy_type_id === 40 &&
          this.type_id === 40
        ) {
          this.isShareAble = false;
        }
      }
      integUseList(data).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  分页操作
     */
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    /*
     *  每页条数
     */
    handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
    /*
     *  新增积分使用规则
     */
    handleAdd() {
      this.type = "add";
      this.visiable = true;
    },
    /*
     *  删除
     */
    handleDelete() {
      this.$confirm("此操作将删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          integUseDel({
            client_use_integral_rule_id: this.client_use_integral_rule_id,
          }).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.getList();
        });
    },
    submit() {
      this.$refs.integralForm.validate((valid) => {
        if (valid) {
          const data = this.integralForm;
          if (this.type === "add") {
            data.hierarchy_id = this.hierarchy_id;

            integUseCreate(data).then((res) => {
              if (res.code === 1) {
                this.$message({
                  type: "success",
                  message: "新增成功!",
                });
                this.cancelDia();
              }
            });
          } else {
            data.client_use_integral_rule_id = this.client_use_integral_rule_id;
            integUseUpdate(data).then((res) => {
              if (res.code === 1) {
                this.$message({
                  type: "success",
                  message: "更新成功!",
                });
                this.cancelDia();
              }
            });
          }
        }
      });
    },
    /*
     *  获取门店数据
     */
    getMerchantListReq() {
      getMerchantListTwo({ status: 10 }).then((res) => {
        if (res.code === 1) {
          this.storeList = res.data;
          this.hierarchy_id = res.data[0].hierarchy_id;
          this.type_id = res.data[0].type_id;
          this.getRule();
        }
      });
    },
    /*
     *  操作
     */
    handleDetails(event, row) {
      this.client_use_integral_rule_id = row.client_use_integral_rule_id;
      if (event === 1) {
        this.type = "edit";
        this.visiable = true;
        this.integralForm = row;
      } else if (event === 2) {
        this.handleDelete();
      }
    },
    /*
     *  关闭弹窗
     */
    cancelDia() {
      this.visiable = false;
      this.integralForm = {
        status: 20,
      };
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .title {
  position: relative;
  margin-bottom: 15px;
  margin-left: 20px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 30px;

  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 15px;
    background: #2791ff;
    left: -8px;
    top: 8px;
    border-radius: 99px;
  }
}

/deep/ .tableTop {
  height: 45px;
  background: linear-gradient(0deg, #f3f9ff, #ffffff);
  padding-top: 10px;
  border: 1px solid #ddd;
  border-radius: 6px 6px 0px 0px;
}
.storeList {
  padding-top: 15px;
  margin-right: 10px;
  background: #ffffff;
  height: 90vh;
  border: 1px solid #ddd;
  border-radius: 6px;

  .store {
    padding-left: 17px;
    width: 100%;
    background: white;
    padding: 10px 0;
    font-size: 14px;

    .text {
      margin-left: 15px;
    }
  }

  .store:hover {
    cursor: pointer;
  }

  .selectStore {
    background-color: #ecf5ff;
    .text {
      margin-left: 14px;
    }
  }
}
</style>